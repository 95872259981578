.formContainer {
	background-color: white;
	border-radius: 5px;
	padding: 20px;
}

.autoComplete {
	min-width: 200px;
}

.dataField {
	display: flex;
	justify-content: center;
	margin: 20px;
	min-width: 200px;
}

.textField {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}
.heading {
	padding: 5px;
	border-radius: 5px;
	margin: 10px 10px 0px 1px;
	background-color: #f7f3ef;
}
.left {
	display: flex;
	justify-content: left;
	align-items: center;
}
.right {
	display: flex;
	justify-content: right;
	align-items: center;
}

.numField {
	margin: 10px;
}

.closeButton {
	font-size: 14px;
	color: #eb9995;
	font-weight: 800;
	cursor: pointer;
	border: 2px solid #eb9995;
	border-radius: 50%;
}

.buttonContainer {
	margin: 0px 20px 0px 20px;
	padding: 10px 0px 0px 0px;
	display: flex;
	justify-content: center;
}

.submitButton {
	height: 35px;
	border: 1px solid #f7901d;
	width: 150px;
	background-color: #f7901d;
	color: white;
}

.loader {
	position: fixed;
}

.submitButton:hover {
	background-color: #fd9325;
	border: 1px solid #fd9325;
}
