.autoComplete {
	min-width: 250px;
	/* width: 250px; */
}
.addButton {
	font-size: 12px;
	color: #f7901d;
	font-weight: 600;
	cursor: pointer;
	border: 1px solid #f7901d;
	margin-bottom: 10px;
	text-align: center;
}
.right {
	display: flex;
	justify-content: right;
	align-items: center;
}
