.editIcon {
	cursor: pointer;
	padding-top: 5px;
	color: #f7901d;
	font-size: 18px;
	font-weight: bold;
	margin-left: 8px;
}

.deleteIcon {
	cursor: pointer;
	padding-top: 5px;
	color: #f7901d;
	font-size: 18px;
	font-weight: bold;
	margin-left: 8px;
}

.table {
	padding: 5px 2px 5px 2px;
}

.highlighterText {
	background-color: #f7901d;
}
