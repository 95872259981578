.adminPageBody {
	margin-top: 60px;
	/* padding: 10px; */
	/* 1920×1080, 1366×768, 1280×1024,1280x768,1280x720,1280x600, 1024×768,800x600,  .WXH */
	@media screen and (min-width: 800px) {
		margin-top: 42px;
	}
	@media screen and (min-width: 1024px) {
		margin-top: 50px;
	}
	@media screen and (min-width: 1280px) {
		margin-top: 60px;
	}
	@media screen and (min-width: 1360px) {
		margin-top: 60px;
	}
	@media screen and (min-width: 1620px) {
		margin-top: 120px;
	}
	@media screen and (min-width: 1920px) {
		margin-top: 120px;
	}
	@media screen and (min-width: 2200px) {
		margin-top: 120px;
	}
}

.reportModal {
	background-color: white;
	border-radius: 10px;
	padding-bottom: 20px;
}
.firstline {
	margin: 10px 2px 10px 2px;
	border-bottom: 2px solid #707070;
}
.secondline {
	margin: 10px 2px 10px 2px;
	border-top: 2px solid #707070;
	padding: 5px;
}
.left {
	display: flex;
	justify-content: left;
	align-items: center;
}

.right {
	display: flex;
	justify-content: right;
	align-items: center;
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}
.firstline {
	margin: 18px 15px 0px 15px;
	border-bottom: 2px solid #707070;
}
.addUserButton {
	background: #f7901d;
	border-radius: 3px;
	padding: 2px 15px;
	margin-bottom: 10px;
	font-size: 12px;
	color: #ffffff;
	text-align: center;
	cursor: pointer;
}

.tableContainer {
	background-color: #f3f3f3;
	padding: 10px;
}

.editIcon {
	cursor: pointer;
	margin-top: 5px;
	color: #f7901d;
}

.table {
	padding: 5px 2px 5px 2px;
}
.closeIcon {
	font-size: 14px;
	color: #f7901d;
	font-weight: 800;
	cursor: pointer;
	border: 2px solid #f7901d;
	border-radius: 50%;
	margin-bottom: 10px;
}

.deleteIcon {
	cursor: pointer;
	margin-top: 5px;
	color: #f7901d;
	font-size: 18px;
	font-weight: bold;
}
