.reportModal {
	background-color: white;
	border-radius: 10px;
	padding-left: 10px;
	padding-right: 20px;
}

.secondline {
	margin: 10px 2px 10px 2px;
	border-top: 2px solid #707070;
	padding: 5px;
}
.left {
	display: flex;
	justify-content: left;
}

.right {
	display: flex;
	justify-content: right;
}

.center {
	display: flex;
	justify-content: center;
}
.firstline {
	margin: 18px 15px 10px 15px;
	border-bottom: 2px solid #707070;
}
.addUserButton {
	background: #f7901d;
	border-radius: 3px;
	padding: 2px 15px;
	margin-bottom: 10px;
	font-size: 12px;
	color: #ffffff;
	text-align: center;
	cursor: pointer;
}

.tableContainer {
	background-color: #f3f3f3;
	padding: 20px;
}

.editIcon {
	cursor: pointer;
	margin-top: 5px;
	color: #f7901d;
}

.table {
	padding: 10px;
}
.closeIcon {
	font-size: 14px;
	color: #f7901d;
	font-weight: 800;
	cursor: pointer;
	border: 2px solid #f7901d;
	border-radius: 50%;
	margin-bottom: 10px;
}

.deleteIcon {
	cursor: pointer;
	margin-top: 5px;
	color: #f7901d;
	font-size: 18px;
	font-weight: bold;
}
