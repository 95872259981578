.floatDiv {
	position: fixed;
	right: 0;
	/* 1920×1080, 1366×768, 1280×1024,1280x768,1280x720,1280x600, 1024×768,800x600,  .WXH */
	@media screen and (min-width: 800px) {
		margin-top: 45px;
	}
	@media screen and (min-width: 850px) {
		margin-top: 45px;
	}
	@media screen and (min-width: 900px) {
		margin-top: 45px;
	}
	@media screen and (min-width: 950px) {
		margin-top: 45px;
	}
	@media screen and (min-width: 1024px) {
		margin-top: 50px;
	}
	@media screen and (min-width: 1100px) {
		margin-top: 55px;
	}
	@media screen and (min-width: 1150px) {
		margin-top: 55px;
	}
	@media screen and (min-width: 1200px) {
		margin-top: 55px;
	}
	@media screen and (min-width: 1280px) {
		margin-top: 65px;
	}
	@media screen and (min-width: 1360px) {
		margin-top: 65px;
	}
	@media screen and (min-width: 1620px) {
		margin-top: 130px;
	}
}
